import React from 'react';

const Footer = () => {
  return (
    <div className="siteBg footertxt">
      <p>COPYRIGHT © 2021 ALL RIGHTS RESERVED</p>
    </div>
  );
};

export default Footer;