import React from 'react'

const Team = () => {

  return (
    <div className="team-section">
      <h1 className='team-section-title text-center mb-0'>
        TEAM
      </h1>
      <div className="container">
        <div className="team-contents d-flex">

          <div className="team-item">
            <div className="">
              <img src="/imgs/figures team Zhe.png" alt="Andrew" />
            </div>
            <h3 className='mb-0'>ANDREW</h3>
            <h4 className='mb-0'>Developer</h4>
            <p className='mb-0'>
              Tech lead from Meta.
              Developed a game that won the Apple’s App Store Award.
              Love in the time of Web3.
            </p>
          </div>

          <div className="team-item">
            <div className="">
              <img src="/imgs/figures team Lettie.png" alt="Andrew" />
            </div>
            <h3 className='mb-0'>LETTIE</h3>
            <h4 className='mb-0'>ARTIST</h4>
            <p className='mb-0'>
              Fun fact 1: the illustrator of the textbook “Human Body: A Wearable Product Designer’s Guide.<br />
              Fun fact 2: did all of theEye-Rolling Uncle production during my pregnancy.
            </p>
          </div>

          <div className="team-item">
            <div className="">
              <img src="/imgs/figures-team Samantha.png" alt="Andrew" />
            </div>
            <h3 className='mb-0'>S.NG</h3>
            <h4 className='mb-0'>MARKETING</h4>
            <p className='mb-0'>
              Former creative agency designer.
              Operated some UN Agency social media accounts you might have seen.
              Former alcoholic. Just turned into a baker.
            </p>
          </div>

          <div className="team-item">
            <div className="">
              <img src="/imgs/figures team Ni 1.png" alt="Andrew" />
            </div>
            <h3 className='mb-0'>TONG</h3>
            <h4 className='mb-0'>BUSINESS DEVELOPMENT</h4>
            <p className='mb-0'>
              Always skip job interviews thanks to my talent in networking.
              New Yorker – if that’s considered a compliment.
              Also a world traveler. Been to more than 50 countries.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Team
