import React from 'react';


const Roadmap = () => {
  return (
		<div className="siteBg pt-8"  style={{ backgroundImage: "url('./imgs/Rectangle 4.png')"}}>
			<div className="roadtlt">
				<p>ROADMAP</p>
			</div>
			<div className="d-flex justify-content-between roadgap">
				<div className="w50"></div>
				<div className="ellipse">
					<img src="./imgs/Ellipse 1.png" className="logo" alt="eyeroll"/>
				</div>
				<div className="w50">
					<div className="roadtxt">
						<h2>25% Sold</h2>
						<p>
							Send out 10 free giveaways to members in the community.
						</p>
					</div>
				</div>
			</div>
			<div className="d-flex justify-content-between roadgap">
				<div className="w50">
					<div className="roadtxt-1">
						<h2>50% Sold</h2>
						<p>
							$ERUC tokens will be created and Eye Rolling Uncles NFT holders could claim them daily. $ERUC could be used to purchase merchandise and the next NFT collection.
						</p>
					</div>
				</div>
				<div className="ellipse">
					<img src="./imgs/Ellipse 1.png" className="logo" alt="eyeroll"/>
				</div>
				<div className="w50"></div>
			</div>
			<div className="d-flex justify-content-between roadgap">
				<div className="w50"></div>
				<div className="ellipse">
					<img src="./imgs/Ellipse 1.png" className="logo" alt="eyeroll"/>
				</div>
				<div className="w50">
					<div className="roadtxt">
						<h2>75% Sold</h2>
						<p>
							A merchandise purchase store will be created.
						</p>
					</div>
				</div>
			</div>
			<div className="d-flex justify-content-between roadgap pb-10">
				<div className="w50">
					<div className="roadtxt-1">
						<h2>100% Sold</h2>
						<p>
							DAO would be set up. Eye Rolling Uncles NFT holders will jointly determine the future of the community.
						</p>
					</div>
				</div>
				<div className="ellipse">
					<img src="./imgs/Ellipse 1.png" className="logo" alt="eyeroll"/>
				</div>
				<div className="w50"></div>
			</div>
    </div>

  );
};

export default Roadmap;