import React, {useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
import { getCurrentWalletConnected } from '../utils/interact';
const { MerkleTree } = require('merkletreejs');

const keccak256 = require('keccak256');
const alchemyKey = process.env.REACT_APP_ALCHEMY_KEY;
const contractABI = require("../contract-abi.json");
const contractAddress = "0x204f910fe8Ca9D405658426CBC34006f924f37D5";
//const contractAddress = "0xC3a2f82C82e36d0E1d2eE4caF0fCF1161a4315C1";
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(alchemyKey);

const contract = new web3.eth.Contract(contractABI, contractAddress); 
const shouldUsePresale = new Date() < new Date(`11 March 2022 04:30:00 UTC`);
//const shouldUsePresale = true;

const mintNFT = async(amount, price) => {  
  const {address} = await getCurrentWalletConnected();
  if (address === "") {
    return {
      success: false, 
      status: "❗Please make sure wallet connected.",           
    }
  } else if (shouldUsePresale) {
    let whitelistAddresses = [
      "0xCB61DcA3E03307D3654759fB83dE34426D0eA09E",
      "0xb681C15282080e7BB75Ab28aE5D20585643Ecb0D",
      "0x514Efb4E858Aa4b32BB0F761529fbcEE5a36B3D2",
      "0xDeAE91De5E342608Cb44B017D838954f1984f258",
      "0x6af903231e6bE97d2BB68b1bd8De0962F2ABc551",// Main
      "0xB519e0c7785E5177D94bf09541Aef93326FF8b1d",// Test Lucy
      "0xF1E4D6e0C7d2898Dfbe12d5287AE8159FBEff4E3",// Test Benny
    ];
    const leafNodes = whitelistAddresses.map(addr => keccak256(addr));
    const merkleTree = new MerkleTree(leafNodes, keccak256, { sortPairs: true});
    const hexProof = merkleTree.getHexProof(keccak256(address));
    contract.methods.mintPresale(amount, hexProof).send({from: address, gasPrice: 30000000000, gas: 300000, value: (amount * price)})
    .on("confirmation", function () {      
    })
    .on('error', async function (error, receipt) {
      console.log(error);      
    }); 
    return {
      status: "",
    }
  } else {
    contract.methods.mintPublicsale(amount).send({from: address, gasPrice: 30000000000, gas: 170000, value: (amount * price)})
    .on("confirmation", function () {      
    })
    .on('error', async function (error, receipt) {
      console.log(error);      
    }); 
    return {
      status: "",
    }
  }
}  

const Mint = () => {
  const mintInfo = useSelector(state => state.mint);
  const [status, setStatus] = useState("");
  const [tokenNumber, setTokenNumber] = useState(1);  
  //const sale_price = shouldUsePresale ? 0.07 * 10**18 : 0.08 * 10**18;
  const sale_price = shouldUsePresale ? 70000000000000000 : 0.08 * 10**18;
  const price = shouldUsePresale ? 0.07 : 0.08;

  const [supply, setSupply] = useState(0);

  const decreaseTokenNumber = () => {
    if (tokenNumber === 1) {
      return;
    }
    setTokenNumber(tokenNumber - 1);
  }

  const increaseTokenNumber = () => {
    if (tokenNumber === 5) {
      return;
    }
    setTokenNumber(tokenNumber + 1);
  }

  const onMintHandle = () => {       
    setStatus("Mint not allowed!");
  };

  const onMintPressed = async () => {          
    const { status } = await mintNFT(tokenNumber, sale_price); 
      setStatus(status);         
  };

  useEffect(() => {    
    async function fetchData() {
      contract.methods.totalSupply().call().then((_supply) => {        
        setSupply(_supply);
      }).catch((err) => console.log(err))      
    }
    fetchData();
  }, []);

  return (
    <div id="nft-bg" className="nftBg pt-20" style={{ backgroundImage: "url('./imgs/Rectangle 4 (2).png')"}}>
      <div className="minttxt">
        <p>EYE ROLLING UNCLE SERIES</p>
      </div>
      <div className="d-flex justify-content-center">
        <div className="mintbox">
          <div className="amount">
            <div className="d-flex justify-content-between">
              <p className="amtxt">Amount</p>
              <div className="d-flex justify-content-between">
                <button className="calbtn calbtn-minus" onClick={decreaseTokenNumber}>-</button>
                <div className="display d-flex justify-content-center items-center">
                  <p>{tokenNumber}</p>
                </div>
                <button className="calbtn calbtn-plus" onClick={increaseTokenNumber}>+</button>
              </div>
            </div>
          </div>
          <div className="subtotal">
            <div className="d-flex justify-content-between aligncenter">
              <p className="amtxt">SUB-TOTAL</p>
              <div className="d-flex justify-content-between">									
                <div className="display1 d-flex justify-content-center items-center">
                  <p>{(tokenNumber * price).toFixed(2) + "ETH"}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="ratiotxt"><p>{supply}/10000</p></div>
          {status ? <div className="text-center ratiotxt text-white">{status}</div> 
            : <div></div>
          }
          {(!mintInfo.publicsale) ? <button className="minbtn" onClick={onMintHandle}>MINT NOW</button> 
            : [(supply >= 10000)? <button className="minbtn mint-disabled text-uppercase" disabled>Sold out</button> : <button className="minbtn mint-active" onClick={onMintPressed}>MINT NOW</button>]
          }			
          <div className="amtxt"> MINT LIMIT is 5 PER TRANSACTION</div>
          <div className="d-flex justify-content-center"><p className="black-bar"></p></div>
		    </div>
      </div>
    </div>
  );
};

export default Mint;